'use client';

import { AuthProvider } from '@/contexts/AuthContext';
import { ScheduledJobsProvider } from '@/contexts/ScheduledJobsContext';
import {
  ThemeSpecs,
  ThemeProvider as LocalThemeProvider,
} from '@/contexts/ThemeContext';
import {
  AuthInfoResponseBody,
  ListCreatedJobsResponseBody,
  ListEntrypointsResponseBody,
} from 'bff';
import { FontFamily } from 'database';
import { ThemeProvider } from 'next-themes';
import React, { useEffect } from 'react';
import { fontClassName } from 'shared-components';
import { buildThemeToken } from 'utils';
import { EntrypointsProvider } from '../contexts/EntrypointsContext';

export const ProtectedProviders = ({
  theme,
  children,
  auth,
  createdJobs,
  entrypoints,
}: {
  theme: ThemeSpecs;
  children: React.ReactNode;
  auth: AuthInfoResponseBody;
  createdJobs: ListCreatedJobsResponseBody;
  entrypoints: ListEntrypointsResponseBody;
}) => {
  const themeToken = buildThemeToken(theme);

  console.log('themeToken', themeToken);

  const fontClass = fontClassName(theme.font_family as FontFamily);

  useEffect(() => {
    if (fontClass) {
      window.document.body.classList.add(fontClass);
    }
  }, []);

  return (
    <EntrypointsProvider entrypoints={entrypoints.entrypoints}>
      <ScheduledJobsProvider initialData={createdJobs}>
        <LocalThemeProvider theme={theme}>
          <AuthProvider user={auth}>
            <ThemeProvider
              attribute='class'
              forcedTheme={themeToken}
              defaultTheme={themeToken}
            >
              {children}
            </ThemeProvider>
          </AuthProvider>
        </LocalThemeProvider>
      </ScheduledJobsProvider>
    </EntrypointsProvider>
  );
};
